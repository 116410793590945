* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.menus {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  font-family: inherit;
  /* font-size: 18px; */
  text-align: center;
}
.image_shown_desktop {
  display: flex;
  margin-right: 1;
  height: 2rem;
  width: 4rem;
  margin: 0rem 0rem 0rem 0.625rem;
}
@media (min-width: 600px) {
  .image_shown_desktop {
    display: flex;
    font-weight: 600;
    height: 3rem;
    width: 10rem;

    margin: 0rem 0rem 0rem 0.625rem;
  }
  .menus {
    display: flex;
    flex-direction: row;
    align-items: center;

    list-style: none;
    text-align: center;
  }
  .dropdown {
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(64, 57, 72, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    color: rgb(135, 132, 132);
    background-color: #c3bebe;
    border-radius: 0.5rem;
    border-top: 0px;
    display: none;
  }

  .dropdown.show {
    display: block;
  }

  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -1px;
    border-top-left-radius: none;
    border-top-right-radius: none;
  }
}

.menu-items {
  position: relative;
  font-size: 0.3rem;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  border-radius: 1rem;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  border-radius: 0.6rem;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0.5rem 0.8rem;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  /* font-size: 0.875rem; */
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  color: rgb(255, 255, 255);
  background-color: #7f7f86;

  border-radius: 0.5rem;
  border-top: 0px;
  display: none;

  transition: 1s;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
  border-top-left-radius: none;
  border-top-right-radius: none;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #aba6a7;
  margin-right: 10px;
}

@media (max-width: 600px) {
  .image_shown_desktop {
    display: none;
    margin-right: 1;
    margin: 0px 0px 0px 10px;
  }
}

.image_shown_mobile {
  display: none;
  margin-right: 1;
  /* height: 2.1rem;
  width: 7rem; */

  border-radius: 50px;
  margin: 0px 0px 0px 10px;
}

@media (max-width: 599px) {
  .image_shown_mobile {
    display: flex;
    margin-right: 1;
    height: 2.1rem;
    width: rem;

    border-radius: 50px;
    margin: 0px 0px 0px 10px;
  }
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 98%;
  transform: scaleX(1);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f8f8f8;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.active-link {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.active-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.active-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
